.footer {
  width: 100%;
  /* height: 100px; */
  padding: 1.5rem;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 600px) {
  .footer {
    flex-direction: row;
  }
}

.footer a {
  padding: 0 6px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; */
}
