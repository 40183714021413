.nav {
  padding: 1rem 0;
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navLink {
  position: relative;
  display: block;
  padding: 10px 16px;
  color: var(--bw-blue-dark);
  opacity: 0.8;
}

.navLinkActive {
  opacity: 1;
  border-left: 6px solid var(--bw-blue);
}

.navLinkLocked {
  opacity: 0.6;
  pointer-events: none;
}
.navLinkLocked::after {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 70 100" xmlns="http://www.w3.org/2000/svg"><path d="M66.487 35.158h-2.601l-.001-6.271C63.882 12.931 50.955.004 35 0 19.044.004 6.118 12.931 6.113 28.887v6.271H3.511a3.335 3.335 0 00-3.335 3.335v58.171A3.335 3.335 0 003.511 100h62.976a3.335 3.335 0 003.336-3.336V38.493a3.335 3.335 0 00-3.336-3.335zm-47.03-6.271c.003-4.315 1.728-8.158 4.551-10.992 2.834-2.824 6.678-4.549 10.992-4.554 4.312.004 8.157 1.729 10.989 4.554 2.826 2.834 4.551 6.677 4.554 10.992v6.271H19.457v-6.271zm19.607 37.394l2.418 19.396c.195 1.556-.93 2.83-2.498 2.83h-7.969c-1.569 0-2.693-1.274-2.498-2.83l2.419-19.396a10.237 10.237 0 118.128 0z" fill="%23005f9e" fill-rule="nonzero"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.navLinkDisabled {
  /* pointer-events: all;
  pointer-events: none; */
  opacity: 0.4;
  font-style: italic;
  position: relative;
  cursor: not-allowed;
}
.navLinkDisabled:hover {
  opacity: 1;
}
.navLinkDisabled:hover::after {
  display: block;
}
.navLinkDisabled::after {
  content: "Under utveckling";
  display: none;
  position: absolute;
  top: -4px;
  right: 0;
  padding: 1rem;
  border-radius: var(--border-radius);
  background-color: #fff;
}

.newLeads {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #f00;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  border-radius: 16px;
  min-width: 24px;
  min-height: 24px;
}
