.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;
}

@media (min-width: 600px) {
  .title {
    font-size: 4rem;
  }
}

.titleSmall {
  margin: 0;
  line-height: 1.15;
  font-size: 2rem;
  text-align: center;
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: var(--bw-blue);
  text-decoration: none;
  border: 1px solid var(--bw-blue);
  background-color: var(--bw-blue-light);
  /* border: 1px solid #eaeaea; */
  border-radius: var(--border-radius);
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 90%;
  opacity: 1;
}

@media (min-width: 600px) {
  .card {
    width: 45%;
  }
}

.cardDisabled {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid var(--border-color);
  background-color: #efefef;
  /* border: 1px solid #eaeaea; */
  border-radius: var(--border-radius);
  width: 90%;
  opacity: 0.4;
  pointer-events: none;
}

@media (min-width: 600px) {
  .cardDisabled {
    width: 45%;
  }
}

.cardLocked {
  opacity: 0.6;
  pointer-events: none;
  position: relative;
}
.cardLocked::after {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 70 100" xmlns="http://www.w3.org/2000/svg"><path d="M66.487 35.158h-2.601l-.001-6.271C63.882 12.931 50.955.004 35 0 19.044.004 6.118 12.931 6.113 28.887v6.271H3.511a3.335 3.335 0 00-3.335 3.335v58.171A3.335 3.335 0 003.511 100h62.976a3.335 3.335 0 003.336-3.336V38.493a3.335 3.335 0 00-3.336-3.335zm-47.03-6.271c.003-4.315 1.728-8.158 4.551-10.992 2.834-2.824 6.678-4.549 10.992-4.554 4.312.004 8.157 1.729 10.989 4.554 2.826 2.834 4.551 6.677 4.554 10.992v6.271H19.457v-6.271zm19.607 37.394l2.418 19.396c.195 1.556-.93 2.83-2.498 2.83h-7.969c-1.569 0-2.693-1.274-2.498-2.83l2.419-19.396a10.237 10.237 0 118.128 0z" fill="%23005f9e" fill-rule="nonzero"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.card:hover,
.card:focus,
.card:active {
  color: var(--bw-blue-dark);
  border-color: var(--bw-blue-dark);
}

/* .cardDisabled:hover,
.cardDisabled:focus,
.cardDisabled:active {} */

.card h2,
.cardDisabled h2 {
  margin: 0 0 1rem 0;
  font-size: 1.4rem;
}

.card p,
.cardDisabled p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
