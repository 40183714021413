.form {
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
  max-width: 400px;

  background-color: #eee;
  border-radius: 3px;
}

.inputrow {
  margin: 0.5rem 0;
}

.label {
  display: block;
  font-size: 14px;
  margin-bottom: 6px;
}

.input {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  font-size: 16px;
}

.submitBtnWrap {
  text-align: center;
}

.submitBtn {
  margin-top: 1rem;
  margin-left: auto;
  /* background-color: #fff; */
}
